import Vue from "vue";
import Vuex from "vuex";
import user from "./user";
import library from "./library";
import settings from "./settings";
import { $api, parseError, markerIcon } from "bh-mod";
import axios from "axios";
const allCats = [
	"airport",
	"art_gallery",
	"bakery",
	"bicycle_store",
	"bus_station",
	"campground",
	"car_dealer",
	"car_rental",
	"car_repair",
	"car_wash",
	"casino",
	"cemetery",
	"city_hall",
	"courthouse",
	"dentist",
	"doctor",
	"electrician",
	"electronics_store",
	"embassy",
	"fire_station",
	"florist",
	"funeral_home",
	"furniture_store",
	"gas_station",
	"grocery_or_supermarket",
	"gym",
	"hair_care",
	"hardware_store",
	"home_goods_store",
	"insurance_agency",
	"jewelry_store",
	"laundry",
	"lawyer",
	"library",
	"light_rail_station",
	"liquor_store",
	"local_government_office",
	"locksmith",
	"lodging",
	"meal_delivery",
	"meal_takeaway",
	"mosque",
	"movie_rental",
	"moving_company",
	"museum",
	"night_club",
	"painter",
	"park",
	"parking",
	"pet_store",
	"pharmacy",
	"physiotherapist",
	"plumber",
	"police",
	"post_office",
	"real_estate_agency",
	"roofing_contractor",
	"rv_park",
	"secondary_school",
	"shoe_store",
	"shopping_mall",
	"spa",
	"stadium",
	"storage",
	"store",
	"subway_station",
	"supermarket",
	"synagogue",
	"taxi_stand",
	"tourist_attraction",
	"train_station",
	"transit_station",
	"travel_agency",
	"veterinary_care",
	"zoo",
];
const Restaurants = {
	Restaurant: "restaurant",
	Bar: "bar",
	Cafe: "cafe",
};
let Schools = {
	"Primary School": "primary_school",
	Universities: "university",
};
let Retail = {
	"Book Store": "book_store",
	"Department Stores": "department_store",
	"Clothing Stores Stores": "clothing_store",
};

let Religious = {
	Church: "church",
	Temples: "hindu_temple",
};
let HealthBeauty = {
	"Drug Stores": "drugstore",
	Hospital: "hospital",
	"Beauty Salon": "beauty_salon",
};
let Finance = {
	Banks: "bank",
	ATMs: "atm",
};
let Outing = {
	Movie: "movie_theater",
};

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		user,
		settings,
		library,
	},
	state: {
		markers: [],
		newTime: 1231232131,
		amenityCats: [
			{
				label: "Restaurants",
				rename: "Restaurants",
				value: Object.values(Restaurants),
				options: Restaurants,
				configure: {
					rating__min: 1,
					price_level__min: 1,
				},
			},
			{
				label: "Schools",
				rename: "Schools",
				value: Object.values(Schools),
				options: Schools,
			},
			{
				label: "Finance",
				rename: "Finance",
				value: Object.values(Finance),
				options: Finance,
			},
			{
				label: "Retail",
				rename: "Retail",
				value: Object.values(Retail),
				options: Retail,
			},
			{
				label: "Religious",
				rename: "Religious",
				value: Object.values(Religious),
				options: Religious,
			},
			{
				label: "Health and Beauty",
				rename: "Health and Beauty",
				value: Object.values(HealthBeauty),
				options: HealthBeauty,
			},
			{
				label: "Outing",
				rename: "Outing",
				value: Object.values(Outing),
				options: Outing,
			},
		],
		allCats,
		instances: [],
		instance: null,
		hives: [],
		cats: [],
		appData: false,
		theApp: {},
		plotCategory: "__dev",
		addAmenityDialog: false,
		editOBJ: {},
		editDialog: false,
		amenityUpdated: 0,
		amenityCategories: {},
		reFetchNeeded: [],
		fetchedMarkers: [],
		pinnedCategories: [],
		orderCat: [],
		pinnedMarkers: [],
		pinnedMarkers2: [],
		showPinned: true,
		plotStatic: true,
		refetchInterval: null,
		appLoading: false,
		uploadDialog: false,
		folders: [],
		highlightedId: "",
		addCategory: {
			visible: false,
		},
		updateMainMap: 0,
		selectedMarker: {
			id: "",
			type: "close",
			markerId: "",
		},
		fileStructure: [],
	},
	mutations: {
		SET_SETTINGS: (state, data) => {
			// set settings
		},
		SET_FILE_STRUCTURE(state, data) {
			state.fileStructure = data;
		},
		SELECT_MARKER(state, data) {
			state.selectedMarker.id = Date.now().toString();
			state.selectedMarker.type = data.type;
			state.selectedMarker.markerId = data.id;
		},
		UPDATE_CATEGORY(state, data) {
			let newObj = JSON.parse(
				JSON.stringify(state.amenityCategories[data.catId])
			);
			newObj.markers = newObj.markers.filter(
				(x) => !data.markers.includes(x.id)
			);
			state.amenityCategories[data.catId] = newObj;
			data.markers.forEach((x) => {
				delete state.appData.data.markers[x];
			});
		},
		REORDER(state, data) {
			let ids = data.map((x) => (x = x.value));
			let pinned1 = ids.findIndex((x) => x == "__pinnedMarkers");
			if (pinned1 != -1) ids.splice(pinned1, 1);
			let pinned2 = ids.findIndex((x) => x == "__pinnedMarkers2");
			if (pinned2 != -1) ids.splice(pinned2, 1);
			let reorder = data;
			$api
				.put(`/amenities/:instance/${state.appData.id}/arrange-cats`, ids)
				.then(({ data }) => {
					if (data.success) {
						state.orderCat = reorder;
					}
				});
		},
		REORDER_CATEGORY(state, data) {
			let { __pinnedMarkers, ...restCat } = state.amenityCategories;
			let catArray = [...Object.values(restCat)];
			let draggedItem = catArray[data.oldIndex];
			catArray.splice(data.oldIndex, 1);
			catArray.splice(data.newIndex, 0, draggedItem);
			catArray.unshift(__pinnedMarkers);
			let ids = catArray.map((x) => (x = x.id));
			$api
				.put(`/amenities/:instance/${state.appData.id}/arrange-cats`, ids)
				.then(({ data }) => {
					if (data.success) {
						let obj = {};
						catArray.forEach((cat) => {
							obj[cat.id] = cat;
						});
						state.amenityCategories = obj;
					}
				});
		},
		plotMarker(state, data) {
			let markerList = state.amenityCategories[data.parent].markers;
			let findIndex = markerList.findIndex((x) => x.id == data.itemValue);
			state.amenityCategories[data.parent].markers.splice(findIndex, 1);
		},
		OPEN_CATEGORY: (state) => {
			state.addCategory.visible = true;
		},
		CLOSE_CATEGORY: (state) => {
			state.addCategory.visible = false;
		},
		REARRANGE_CATS: (state, ids) => {
			ids.forEach((id, index) => {
				state.amenityCategories[id].order = index;
			});
		},
		HIGHLIGHT_MARKER: (state, id) => (state.highlightedId = id),
		APP_LOAD: (state, status = true) => (state.appLoading = status),
		DO_CATEGORIES: (state, data = state.appData) => {
			if (!data) {
				return;
			}
			let doCategories = data;
			state.amenityCategories = [];
			state.pinnedMarkers = [];
			doCategories.data.markers["__dev"] = {
				pinned: true,
				categoryId: "__pinnedMarkers",
				geometry: {
					location: {
						lat: state.appData.lat,
						lng: state.appData.lng,
					},
				},
				icon: state.appData.logo,
				id: "__dev",
				name: state.appData.name || state.instance.name,
			};
			let markers =
				data.data && data.data.markers
					? Object.values(doCategories.data.markers)
					: [];
			markers = [...markers];

			let categories = (data.data && data.data.categories) || {};

			state.markers = markers;

			Object.values(categories).forEach((x) => {
				x.markers = [];
				if (!x.icon) x.icon = markerIcon;
				if (x.processing && !state.reFetchNeeded.includes(x.id))
					state.reFetchNeeded.push(x.id);
			});
			state.pinnedMarkers2 = [];

			markers.forEach((mark) => {
				let { categoryId } = mark;
				if (categoryId === "__pinnedMarkers") {
					state.pinnedMarkers2.push(mark);
				}

				if (
					categoryId &&
					categories[categoryId] &&
					categories[categoryId].icon &&
					categories[categoryId].icon.trim()
				)
					mark.icon = categories[categoryId].icon.trim();

				if (categories[categoryId] && categories[categoryId].id) {
					let category = categories[categoryId];
					category.markers.push(mark);
				}
			});

			let pinned = {
				__dev: {
					id: "__dev",
					name: "Development Location",
					icon: state.appData.logo,
					isStatic: true,
					appLocation: true,
					markers: [
						{
							name: state.appData.name || state.instance.name,
							geometry: {
								location: {
									lat: state.appData.lat,
									lng: state.appData.lng,
								},
							},
							categoryId: "__dev",
							appLocation: true,
						},
					],
				},
			};

			let notPinned = {
				__pinnedMarkers: {
					id: "__pinnedMarkers",
					name: "Highlight Markers",
					icon:
						"https://bildhive.nyc3.digitaloceanspaces.com/7ce705321e9b41149e994384000ae2db.png",
					isStatic: false,
					markers: state.pinnedMarkers2,
					appLocation: true,
					processing: false,
					value: [],
				},
			};

			Object.values(categories).forEach((x) => {
				if (x.isStatic) pinned[x.id] = x;
				else notPinned[x.id] = x;
			});
			state.amenityCategories = notPinned;
			state.pinnedCategories = pinned;
		},
		editMarker: (state, id) => {
			if (
				state.appData.data.markers &&
				typeof id === "string" &&
				state.appData.data.markers[id]
			) {
				state.editOBJ = state.appData.data.markers[id];
				state.editDialog = true;
			}
		},
		editCategory: (state, cat) => {
			if (cat.id === "__dev") {
				state.editOBJ = cat;
			} else {
				state.editOBJ = state.appData.data.categories[cat.id];
			}
			state.editDialog = true;
		},
		showAddAmenity: (state, type = "marker") => {
			state.addAmenityDialog = type;
		},
		plotCategory: (state, cat) => {
			state.plotCategory = cat.id;
		},
		cancelUpload: (state) => {
			state.uploadDialog = false;
		},
		uploadDialog: (state, folders = []) => {
			state.uploadDialog = true;
			state.folders = folders;
		},
		SET_INSTANCE: (state, data) => {
			state.instance = data;
			state.cats = data.activeApps;
		},
		SET_APPDATA: (state, data) => {
			state.appData = data;
		},
		RESET_MAP: (state, full) => {
			if (full) {
				state.plotStatic = true;
				let key = "__dev";
				if (Object.keys(state.amenityCategories).length)
					key = Object.keys(state.amenityCategories)[0];
				return (state.plotCategory = key);
			}

			let oldID = state.plotCategory;
			state.plotCategory = "";
			state.plotCategory = oldID;
		},
		changeFolder(state, key) {
			state.currentFolder = key;
		},
		FILE_IT(state, data) {
			if (!data["/"]) data["/"] = [];
			state.files = data;
			state.currentFolder = "/";
		},
		UPLOADED_FILES(state, data) {
			let folder = data[0].folder;
			if (!state.files[folder]) return Vue.set(state.files, folder, data);

			let oldFiles = state.files[folder];
			data = [...oldFiles, ...data];
			return Vue.set(state.files, folder, data);
		},
		DELETE_FILE(state, data) {
			let id = data.id;
			let folder = data.folder;
			let newArray = state.files[folder].filter((x) => x.id !== id);
			return Vue.set(state.files, folder, newArray);
		},
		SET_APP(state, data) {
			state.theApp = data;
		},
		RESET_DRAWER(state) {
			state.addAmenityDialog = false;
			state.editDialog = false;
			state.editOBJ = {};
		},
		UPDATE_AMENITY(state, { type, data }) {
			if (type === "new" || type === "edit") {
				if (data.categoryId) {
					state.appData.data.markers[data.id] = data;
				} else {
					state.appData.data.categories[data.id] = data;
					if (data.processing && !state.reFetchNeeded.includes(data.id))
						state.reFetchNeeded.push(data.id);
				}
			} else if (type === "delete") {
				if (data.categoryId) {
					delete state.appData.data.markers[data.id];

					let findIndex = state.amenityCategories[
						data.categoryId
					].markers.findIndex((x) => x.id == data.id);
					state.amenityCategories[data.categoryId].markers.splice(findIndex, 1);
				} else {
					delete state.appData.data.categories[data.id];
				}
			}
		},
		UPDATE_DEV: (state, { lat, lng, name, logo, theme }) => {
			state.appData.lat = lat;
			state.appData.lng = lng;
			state.appData.name = name;
			state.appData.logo = logo;
			state.appData.theme = theme;
		},
		UPDATE_MAIN_MAP(state) {
			state.updateMainMap = Date.now();
		},
	},

	actions: {
		editCategory({ commit }, cat) {
			commit("editCategory", cat);
		},
		closeAmenityDrawers: ({ commit, dispatch }, { type, data }) => {
			commit("RESET_DRAWER");
			if (!data.id) return null;
			commit("APP_LOAD");

			if (type === "dev") {
				commit("UPDATE_DEV", data);
				commit("DO_CATEGORIES");
				commit("RESET_MAP");
			} else if (type === "bulkdelete") {
				commit("DO_CATEGORIES");
				dispatch("SET_INTERVAL");
				commit("RESET_MAP");
			} else {
				commit("UPDATE_AMENITY", { type, data });
				commit("DO_CATEGORIES");
				dispatch("SET_INTERVAL");
				commit("RESET_MAP", type === "delete" && !data.categoryId);
			}
			commit("APP_LOAD", false);
		},
		DO_FETCH: ({ state, commit }) => {
			return new Promise((resolve) => {
				let cont = true;
				if (state.reFetchNeeded.length === 0) resolve(true);

				$api
					.post(
						`/amenities/${state.instance.id}/${state.appData.id}/categories`,
						{ ids: state.reFetchNeeded }
					)
					.then(({ data }) => {
						if (data.length) {
							let reFetchNeeded = [];
							state.fetchedMarkers = [];

							data.forEach((x) => {
								if (x.processing && !reFetchNeeded.includes(x.id))
									reFetchNeeded.push(x.id);
								else if (x.markers && x.markers.length) {
									state.appData.data.categories[x.id].processing = false;
									x.markers.forEach((y) => {
										state.appData.data.markers[y.id] = y;
										state.fetchedMarkers.push(y);
									});
								}
							});
							if (state.fetchedMarkers.length) commit("DO_CATEGORIES");

							state.reFetchNeeded = reFetchNeeded;
							cont = state.reFetchNeeded.length === 0;

							resolve(!cont);
						} else {
							resolve(false);
						}
					})
					.catch((err) => {
						return resolve(false);
					});
			});
		},
		SET_INTERVAL: ({ state, dispatch }) => {
			if (state.reFetchNeeded.length) {
				if (state.refetchInterval) clearInterval(state.refetchInterval);
				state.refetchInterval = setInterval(async () => {
					if (state.reFetchNeeded.length) await dispatch("DO_FETCH");
					else clearInterval(state.refetchInterval);
				}, 10000);
			}
		},
		SET_APPDATA: async ({ commit, dispatch }, data) => {
			commit("SET_APPDATA", data);
			commit("DO_CATEGORIES");
			commit("RESET_MAP");
			dispatch("SET_INTERVAL");
		},
	},
	getters: {},
});
