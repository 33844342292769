<template>
    <div
        class="w-full h-full dF fC aS"
        style="
            display: inline-block;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
        "
    >
        <LeftNavBar
            @changeCurrentCat="changeCurrentCat"
            @scrollItem="scrollItem"
            :openNav="openLeftNav"
            :selectedValue="plotCat"
            :bulkSelect="true"
            :drag="true"
            @order="reorderItems"
            @optionclick="doSOmethingWIthOption"
            class="f1"
            :items="amenityCategoriesParsed"
            @chosen="doSomethingHere"
            @opened="catOpened"
            @bulkDelete="bulkDelete"
            :title="selectedAppInitial"
            :imgSrc="imgUrl"
            :hasChild="true"
            :hasPinned="true"
            @previousMarker="changePreviousMarker"
        >
            <h4>No Marker Found</h4>
            <p>Please start adding Markers</p>
        </LeftNavBar>
    </div>
</template>

<script>
import LeftNavBar from "bh-mod/components/common/LeftNavBar";

export default {
    components: { LeftNavBar },
    data() {
        return {
            orderItems: [],
            openID: "__dev",
            deleteObj: {},
            imgUrl: "https://bildhive.nyc3.digitaloceanspaces.com/appIcons/amenity-asset.svg",
            previousMarker: "",
        };
    },
    watch: {
        orderItems: {
            handler(val) {
                this.$store.commit("REORDER", val);
            },
        },

        plotCat(val) {
            this.openID = val;
        },
        updateMainMap() {
            this.openID = "";
            this.$store.commit("plotCategory", { id: "" });
        },
    },
    computed: {
		selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "AMC"
            );
        },
        openLeftNav() {
            return this.$store.state.selectedMarker;
        },
        amenityCategories() {
            let { __pinnedMarkers, ...restCat } =
                this.$store.state.amenityCategories;
            let cats = [__pinnedMarkers];
            return [
                ...cats,
                ...Object.values(restCat).sort((a, b) => {
                    if (a.order > b.order) return 1;
                    else return -1;
                }),
            ];
        },
        amenityCategoriesParsed() {
            let list = this.$store.state.orderCat;
            if (list.length != 0) {
                return list;
            }
            let amenityCategories = [];
            for (var i = 0; i < this.amenityCategories.length; i++) {
                let catObj = {
                    label: "",
                    value: "",
                    iconUrl: "",
                    loading: false,
                    options: [
                        {
                            label: "Edit Category",
                            value: "editCategory",
                        },
                        {
                            label: "Delete Category",
                            value: "deleteCategory",
                        },
                    ],
                    children: [],
                };
                catObj.label = this.amenityCategories[i].name;
                catObj.value = this.amenityCategories[i].id;
                catObj.iconUrl = this.amenityCategories[i].icon;
                if (this.amenityCategories[i].order)
                    catObj.order = this.amenityCategories[i].order;
                catObj.loading = this.amenityCategories[i].processing;
                if (this.amenityCategories[i].id === "__pinnedMarkers") {
                    catObj.bb = true;
                }
                for (
                    var j = 0;
                    j < this.amenityCategories[i].markers.length;
                    j++
                ) {
                    let catChildren = {
                        label: "",
                        value: "",
                        parent: "",
                        options: [
                            {
                                label: "Edit Marker",
                                value: "editMarker",
                            },
                            {
                                label: "Delete Marker",
                                value: "deleteMarker",
                            },
                        ],
                    };

                    if (this.amenityCategories[i].markers[j].id == "__dev") {
                        catChildren.options = [
                            {
                                label: "Edit Marker",
                                value: "editMarker",
                            },
                        ];
                    }
                    catChildren.label =
                        this.amenityCategories[i].markers[j].name;
                    catChildren.value = this.amenityCategories[i].markers[j].id;
                    catChildren.parent = this.amenityCategories[i].id;
                    catObj.children.push(catChildren);
                }
                amenityCategories.push(catObj);
                if (this.amenityCategories[i].id === "__pinnedMarkers") {
                    catObj.fixed = true;
                    let newObj = JSON.parse(JSON.stringify(catObj));
                    newObj.label = newObj.label + "2";
                    newObj.value = newObj.value + "2";
                    newObj.divider = true;
                    newObj.fixed = true;
                    amenityCategories.push(newObj);
                }
            }

            amenityCategories.forEach((x) => {
                x.children = x.children.sort((a, b) => {
                    if (a.value == "__dev" || b.value == "__dev") {
                        return a.value == "__dev" ? -1 : b == "__dev" ? 1 : 0;
                    } else {
                        if (a.label > b.label) return 1;
                        else return -1;
                    }
                });
            });
            if (
                amenityCategories.length &&
                amenityCategories[amenityCategories.length - 1].order
            ) {
                amenityCategories = amenityCategories.sort((a, b) => {
                    if (a.order > b.order) return 1;
                    else if (a.order < b.order) return -1;
                    else return 0;
                });
            } else {
                amenityCategories = amenityCategories.sort((a, b) => {
                    if (a.label > b.label) return 1;
                    else if (a.label < b.label) return -1;
                    else return 0;
                });
            }
            let highlightCat = amenityCategories.findIndex(
                (x) => x.value == "__pinnedMarkers"
            );
            let highlightCat2 = amenityCategories[highlightCat];
            amenityCategories.splice(highlightCat, 1);
            let highlightCatDiv = amenityCategories.findIndex(
                (x) => x.value == "__pinnedMarkers2"
            );
            let highlightCatDiv2 = amenityCategories[highlightCatDiv];
            amenityCategories.splice(highlightCatDiv, 1);

            let newArray = [highlightCat2].concat(
                [highlightCatDiv2],
                amenityCategories
            );
            return newArray;
        },
        highlightedId() {
            return this.$store.state.highlightedId || "";
        },
        theApp() {
            return this.$store.state.theApp || {};
        },
        appData() {
            return this.$store.state.appData || {};
        },
        amenCats() {
            return Object.values(this.$store.state.amenityCategories) || {};
        },
        pinnedCategories() {
            return Object.values(this.$store.state.pinnedCategories || {});
        },
        plotCat() {
            return this.$store.state.plotCategory;
        },
        instance() {
            return this.$store.state.instance;
        },
        mapMarkers() {
            return this.$store.state.appData.data.markers;
        },
        updateMainMap() {
            return this.$store.state.updateMainMap;
        },
    },
    methods: {
        changePreviousMarker(id) {
            this.previousMarker = this.mapMarkers[id];
        },
        changeCurrentCat(id) {
            this.$store.commit("plotCategory", { id: id });
        },
        scrollItem() {
            setTimeout(() => {
                let elmnt = document.querySelectorAll(
                    `[data-file-id="${this.openLeftNav.markerId}"]`
                );
                let list = document.getElementsByClassName("children-list");
                if (list && elmnt[0]) {
                    list[0].scroll(0, elmnt[0].offsetTop - 110);
                }
            }, 500);
        },
        bulkDelete(e) {
            let sendObj = {
                markers: e,
            };
            this.$api
                .put(
                    `/amenities/:instance/${this.appData.id}/${this.plotCat}/bulk`,
                    sendObj
                )
                .then(({ data }) => {
                    if (data.success) {
                        let newObj = {
                            markers: sendObj.markers,
                            catId: this.plotCat,
                        };
                        this.$store.commit("UPDATE_CATEGORY", newObj);
                        setTimeout(() => {
                            this.onClose({
                                type: "bulkdelete",
                                data: this.$store.state.amenityCategories[
                                    newObj.catId
                                ],
                            });
                        }, 100);
                    }
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});
        },
        reorderItems(e) {
            let test = this.amenityCategoriesParsed;
            this.orderItems = [...test];
        },
        doSOmethingWIthOption(data) {
            if (data.optionValue == "editCategory") {
                let cat = this.amenityCategories.find(
                    (x) => x.id === data.itemValue
                );
                this.$store.commit("plotCategory", cat);
                this.$store.dispatch(
                    "editCategory",
                    this.$store.state.amenityCategories[
                        this.$store.state.plotCategory
                    ]
                );
            }
            if (data.optionValue == "editMarker") {
                if (data.itemValue == "__dev") {
                    this.$store.commit("editCategory", {
                        id: "__dev",
                        name: "Edit Map",
                        icon: this.$store.state.appData.logo,
                        isStatic: true,
                        appLocation: true,
                    });
                } else {
                    this.$store.commit("editMarker", data.itemValue);
                }
            }
            if (data.optionValue == "deleteCategory") {
                this.deleteObj = data;
                let self = this;
                this.$confirm({
                    title: "Delete Category?",
                    content: (h) => (
                        <div>
                            Do you want to delete the Category? Deleting will
                            permanently remove the Category
                        </div>
                    ),
                    okText: "Delete",
                    okType: "danger",
                    cancelText: "Cancel",
                    centered: true,
                    onOk() {
                        self.confirmDelete();
                    },
                    onCancel() {
                        console.log("Cancel");
                    },
                });
            }
            if (data.optionValue == "deleteMarker") {
                this.deleteObj = data;
                let self = this;
                this.$confirm({
                    title: "Delete Marker?",
                    content: (h) => (
                        <div>
                            Do you want to delete the Marker? Deleting will
                            permanently remove the Marker
                        </div>
                    ),
                    okText: "Delete",
                    okType: "danger",
                    cancelText: "Cancel",
                    centered: true,
                    onOk() {
                        self.confirmDelete();
                    },
                    onCancel() {
                        console.log("Cancel");
                    },
                });
            }
        },
        confirmDelete() {
            let deleteURL = `/amenities/${this.instance.id}/${this.appData.id}/${this.deleteObj.itemValue}/all`;
            if (this.deleteObj.type == "child")
                deleteURL = `/amenities/${this.instance.id}/${this.appData.id}/${this.deleteObj.parent}/${this.deleteObj.itemValue}`;

            this.$api.delete(deleteURL).then(({ data }) => {
                this.$store.commit("UPDATE_AMENITY", {
                    type: "delete",
                    data: { id: this.deleteObj.itemValue },
                });
                let obj = {};
                let currentObj = {};
                if (this.deleteObj.type == "parent") {
                    for (var i = 0; i < this.amenityCategories.length; i++) {
                        if (
                            this.amenityCategories[i].id ==
                            this.deleteObj.itemValue
                        ) {
                            obj = this.amenityCategories[i];
                        }
                    }
                } else {
                    for (var i = 0; i < this.amenityCategories.length; i++) {
                        if (
                            this.amenityCategories[i].id ==
                            this.deleteObj.parent
                        ) {
                            currentObj = this.amenityCategories[i];
                            console.log("CURRENTOBJ", currentObj);
                        }
                    }
                    for (var j = 0; j < currentObj.markers.length; j++) {
                        if (
                            currentObj.markers[j].id == this.deleteObj.itemValue
                        ) {
                            obj = currentObj.markers[j];
                        }
                    }
                }
                this.onClose({ type: "delete", data: obj });
                if (this.deleteObj.type == "parent")
                    this.$store.commit("plotCategory", { id: "" });
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			});
        },
        catOpened(item) {
            let cat = this.amenityCategories.find((x) => x.id === item.value);
            this.$store.commit("plotCategory", cat);
        },
        doSomethingHere(data) {
            this.$store.commit("SELECT_MARKER", {
                type: "open",
                id: data.value,
            });
            this.mapInfo(this.previousMarker, "close");
            this.mapInfo(this.mapMarkers[data.value], "open");
            this.previousMarker = this.mapMarkers[data.value];
        },
        updateCats() {
            let ids = this.amenityCategories.map((x) => x.id);
            this.$api.put(
                `/amenities/${this.$store.state.instance.id}/${this.$store.state.appData.id}/arrange-cats`,
                ids
            ).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			});
            this.$store.commit("REARRANGE_CATS", ids);
        },
        editMarker(marker) {
            this.$store.commit("editMarker", marker.id);
        },

        openingCategory(cat) {
            if (this.openID === cat.id) {
                this.openID = "";
                this.$store.commit("plotCategory", { id: "" });
            } else {
                this.openID = cat.id;
                this.$store.commit("plotCategory", cat);
            }
        },
        mapInfo(marker, action) {
            if (!marker.markerOBJ) return null;

            if (action === "open") marker.markerOBJ.openInfoWindow();
            else marker.markerOBJ.closeInfoWindow();
        },
        onClose({ type = "", data = {} }) {
            this.$store.dispatch("closeAmenityDrawers", { type, data });
        },
    },
    created() {},
};
</script>

<style lang="scss" scoped>
.amenityCats {
    .del-category,
    .del-marker {
        pointer-events: none;
        opacity: 0;
        padding-right: 0 !important;
        transition: opacity 0.3s ease-out;
    }
    .ant-menu-submenu-title {
        &:hover {
            .del-category {
                pointer-events: all;
                opacity: 1;
            }
        }
    }
    .marker-item {
        padding-left: 30px !important;
        width: calc(100% - 35px);
        position: relative;
        overflow: visible;
        .del-marker {
            position: absolute;
            right: -30px;
            transform: translateY(-50%);
            top: 50%;
            &:hover {
                opacity: 1 !important;
            }
        }
        &:hover {
            .del-marker {
                opacity: 1;
            }
        }
    }
    li[role="menuitem"] {
        position: relative;
        border-bottom: 1px solid var(--off-white-dark);
        &.loading-category {
            [aria-label="icon: loading"] {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate3d(0, -50%, 0);
            }
            .ant-menu-submenu-arrow,
            .del-category {
                opacity: 0;
            }
        }
    }
    ul[role="menu"].ant-menu-sub {
        max-height: 400px;
        overflow: hidden scroll;
        background: var(--off-white-light);
    }
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.highlight-this {
    color: var(--orange);
}

.menu-button {
    font-size: 30px;
    color: var(--black);
}
</style>

<style lang="scss">
</style>
